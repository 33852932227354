'use client';

import { useEffect } from 'react';

import { useRouter } from 'next/navigation';

import { DarkModeSwitcher } from '@quick/components/DarkModeSwitcher';

/**
 * Handles redirection on 404.
 * Also renders DarkModeSwitcher
 */
export function Redirector() {
  const router = useRouter();

  useEffect(() => {
    if (window) {
      const t = setTimeout(() => {
        if (document.referrer.split('/')[2] != location.hostname) {
          router.replace('/');
        } else {
          router.back();
        }
      }, 5_000);
      return () => clearTimeout(t);
    }
  }, [router]);
  return (
    <div className="fixed right-0 z-10 p-2 text-2xl">
      <DarkModeSwitcher />
    </div>
  );
}
