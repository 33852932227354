'use client';

import { useEffect } from 'react';

export function useCSSHasLoader() {
  // const fst = useRef(false);
  useEffect(() => {
    // if (fst.current) return;
    //@ts-expect-error Module not typed
    import('css-has-pseudo/browser').then((m) => m.default(document));
    // fst.current = true;
  }, []);
}
